/* @font-face {
  font-family: Hyperion;
  src: url('./fonts/Hyperion_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: JetBrainsMono;
  src: url('./fonts/JetBrainsMono-Medium.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: JetBrainsMono;
  src: url('./fonts/JetBrainsMono-ExtraLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

:root {
  --color-bg: #1a1b1e;
  --color-content: #ffffff;
  --color-base: #000000;
  --color-container: #303134;
  --color-light-container: #d9d9d9;

  --font-title: 'Hyperion', Arial, sans-serif;
  --font-regular: 'Montserrat', sans-serif;

  --color-bg: #1a1b1e;
  --color-content: #ffffff;
  --color-base: #000000;
  --color-container: #0a0a0a;
  --color-container-opacity: hsla(255, 40%, 2%, 0.744);
  --color-light-container: #d9d9d9;
  --color-card-bg: #121212;
  --color-button-primary: linear-gradient(90.28deg, #ff8a00 0%, #d449f7 100%);
  --color-button-active: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(84.75deg, #ff8a00 -55.87%, #d449f7 40.4%);
  --font-title: 'Montserrat', serif;
  --font-regular: 'Montserrat', serif;
  --breakpoint-mobile: 600px;
  --breakpoint-tablet: 768px;
  --breakpoint-laptop: 992px;
  --breakpoint-desktop: 1024px;
  --breakpoint-huge: 1280px;
}

@property --color-button-light {
  syntax: '<color>';
  initial-value: #f8f9fb;
  inherits: false;
}

@property --color-button-light-second {
  syntax: '<color>';
  initial-value: #f8f9fb;
  inherits: false;
}

@property --color-button-dark {
  syntax: '<color>';
  initial-value: #202020;
  inherits: false;
}

@property --color-button-dark-second {
  syntax: '<color>';
  initial-value: #202020;
  inherits: false;
}

@property --gradient-percent-1 {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: false;
}

@property --gradient-percent-2 {
  syntax: '<percentage>';
  initial-value: 100%;
  inherits: false;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  font-family: 'Montserrat', sans-serif;
  color: #000;
}

p {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 0;
}